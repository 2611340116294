import { apiRoute, authHeader, handleResponse } from '../helpers'

export const licenseService = {
  getEntitlement,
  loadLicense,
  activateLicense
}

function getEntitlement () {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  }

  return fetch(apiRoute() + '/api/v1/license', requestOptions).then(handleResponse)
}

function loadLicense (license) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(license)
  }

  return fetch(apiRoute() + '/api/v1/license', requestOptions).then(handleResponse)
}

function activateLicense (licenseId) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  }

  return fetch(apiRoute() + '/api/v1/license/' + licenseId, requestOptions).then(handleResponse)
}
