export const jwtHelper = {
  getUser: function () {
    const stored = localStorage.getItem('user')
    if (!stored) {
      return null
    }

    const user = JSON.parse(stored)
    if (!user) {
      return null
    }

    return user
  },
  isValid: function (jwt) {
    if (!jwt || jwt.split('.').length < 3) {
      return false
    }
    const data = JSON.parse(atob(jwt.split('.')[1]))
    const exp = new Date(data.exp * 1000)
    const now = new Date()
    const checkDate = new Date(now - 120000)
    return checkDate < exp
  },
  isStoredValid: function () {
    const user = this.getUser()
    if (!user || !user.token) {
      return false
    }

    const jwt = user.token
    return this.isValid(jwt)
  },
  canRefresh: function () {
    const user = this.getUser()
    if (!user || !user.refreshToken) {
      return false
    }

    const jwt = user.refreshToken
    return this.isValid(jwt)
  }
}
