<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      alert: state => state.alert
    }),
    alertState () {
      return this.alert.counter
    }
  },
  watch: {
    alertState (_new, _old) {
      if (this.alert.type) {
        // // console.log('alerts', _new, _old)
        const _variant = this.alert.type.replace('alert-', '')
        if (_variant !== 'none') {
          let _message = this.alert.message || this.alert.status
          // // console.log(_message)
          if (!_message) {
            _message = 'There was a technical error processing your request. Please, try again later.'
          }
          this.$bvToast.toast(_message, {
            title: 'RATify Orchestrator',
            toaster: 'b-toaster-top-right',
            variant: _variant,
            solid: true,
            appendToast: true,
            autoHideDelay: 10000
          })
        }
      }
    }
  }
}
</script>

<style scoped>
.footer {
  position: fixed;
  bottom: 0;
  right: 0;
  font-size: 0.75rem;
  padding: 0.5rem;
  color: rgba(255, 255, 255, 0.8);
  z-index: 1000;
}
</style>
