export function handleResponse (response) {
  return response.text().then(text => {
    const data = text && text !== 'Unauthorized' && tryParse(text)
    if (!response.ok) {
      let error = (data && data.message) || response.statusText
      if (typeof error === 'object') {
        error = response.statusText
      }
      return Promise.reject(error)
    }

    if (response.status === 204) {
      return null
    }

    return data
  })
}

function tryParse (rawValue) {
  try {
    return JSON.parse(rawValue)
  } catch (e) {
    return null
  }
}
