import { licenseService } from '../services'

const state = {
  info: null,
  status: {
    loading: false,
    loaded: false,
    notFound: false,
    saving: false,
    saved: false,
    progress: 0
  }
}

const actions = {
  get ({ dispatch, commit }) {
    commit('getLicense')

    licenseService.getEntitlement()
      .then(
        license => {
          if (!license) {
            commit('getLicenseNotFound')
          } else {
            commit('getLicenseSuccess', license)
          }

          dispatch('alert/clear', null, { root: true })
        },
        error => {
          commit('getLicenseFailure')
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  update ({ dispatch, commit }, payload) {
    commit('updateLicense')

    licenseService.loadLicense(payload)
      .then(
        () => {
          commit('updateLicenseSuccess')
          dispatch('alert/clear', null, { root: true })
          dispatch('alert/success', 'License loaded successfully ...', { root: true })
        },
        error => {
          commit('updateLicenseFailure')
          dispatch('alert/error', error, { root: true })
        }
      )
  }
}

const mutations = {
  getLicense (state) {
    state.info = null
    state.status = {
      loading: true,
      loaded: false,
      notFound: false,
      saving: false,
      saved: false,
      progress: 0
    }
  },
  getLicenseNotFound (state) {
    state.info = null
    state.status = {
      loading: false,
      loaded: false,
      notFound: true,
      saving: false,
      saved: false,
      progress: 0
    }
  },
  getLicenseFailure (state) {
    state.info = null
    state.status = {
      loading: false,
      loaded: true,
      notFound: false,
      saving: false,
      saved: false,
      progress: 0
    }
  },
  getLicenseSuccess (state, license) {
    state.info = license
    state.status = {
      loading: false,
      loaded: true,
      notFound: false,
      saving: false,
      saved: false,
      progress: 0
    }
  },
  updateLicense (state) {
    state.status = {
      loading: false,
      loaded: false,
      notFound: false,
      saving: true,
      saved: false,
      progress: 0
    }
  },
  updateLicenseFailure (state) {
    state.status = {
      loading: false,
      loaded: false,
      notFound: false,
      saving: false,
      saved: true,
      progress: 0
    }
  },
  updateLicenseSuccess (state) {
    state.status = {
      loading: false,
      loaded: false,
      notFound: false,
      saving: false,
      saved: true,
      progress: 0
    }
  }
}

export const license = {
  namespaced: true,
  state,
  actions,
  mutations
}
