import { apiRoute, authHeader, handleResponse, jwtHelper, removeUser } from '../helpers'

export const authService = {
  login,
  change,
  logout,
  refresh,
  register,
  getOpenId,
  loginResponse
}

function login (user) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user)
  }

  return fetch(apiRoute() + '/auth/v1/login', requestOptions)
    .then(handleResponse)
    .then(user => {
      if (user.token) {
        localStorage.setItem('user', JSON.stringify(user))
      }

      return user
    })
}

function change (passwords) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(passwords)
  }

  return fetch(apiRoute() + '/api/v1/user/change', requestOptions).then(handleResponse)
}

function logout () {
  removeUser()
}

function refresh () {
  const user = jwtHelper.getUser()
  if (user && user.refreshToken) {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ token: user.refreshToken })
    }

    return fetch(apiRoute() + '/auth/v1/refresh', requestOptions)
      .then(handleResponse)
      .then(user => {
        if (user.token) {
          localStorage.setItem('user', JSON.stringify(user))
        }

        return user
      })
  } else {
    return null
  }
}

function register (user) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user)
  }

  return fetch(apiRoute() + '/auth/v1/register', requestOptions).then(handleResponse)
}

function getOpenId (redirect) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  }

  return fetch(apiRoute() + '/auth/v1/login?redirect=' + redirect, requestOptions).then(handleResponse)
}

function loginResponse (params) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  }

  let _query = '?'
  for (const propName in params) {
    _query += propName + '=' + params[propName] + '&'
  }

  return fetch(apiRoute() + '/auth/v1/login/response' + _query.slice(0, -1), requestOptions)
    .then(handleResponse)
    .then(user => {
      if (user.token) {
        localStorage.setItem('user', JSON.stringify(user))
      }

      return user
    })
}
