<template>
  <div id="MainLayout" class="row-offcanvas row-offcanvas-left">
    <nav class="sidebar-offcanvas sidebar">
      <a class="navbar-brand" href="#"><img :src="require('@/assets/images/logo-icon.svg')" class="m-auto d-block img-fluid" /></a>
      <b-nav class="flex-column align-self-end">
        <b-nav-item class="mx-auto" active>
          <div class="pt-1"><font-awesome-icon icon="lungs" size="2x" /></div>
        </b-nav-item>
      </b-nav>
      <b-nav class="flex-column bottom">
        <b-nav-item class="mx-auto" @click="$bvModal.show('license_info')" :disabled="!licenseStatus || !licenseStatus.loaded">
          <div class="pt-2">
            <font-awesome-icon icon="id-badge" size="2x" /><br />
            <span class="text">License Allocation</span>
          </div>
        </b-nav-item>
        <b-nav-item class="mx-auto" @click="changePassword()">
          <div class="pt-2">
            <font-awesome-icon icon="user-lock" size="2x" />
            <span class="text">Change Password</span>
          </div>
        </b-nav-item>
        <b-nav-item class="mx-auto" @click="doLogout()">
          <div class="pt-2">
            <font-awesome-icon icon="sign-out-alt" size="2x" />
            <span class="text">Logout</span>
          </div>
        </b-nav-item>
      </b-nav>
    </nav>
    <main role="main" class="main">
      <router-view/>
    </main>
    <!-- Confirmation modal -->
    <b-modal id="change_password" size="lg" centered hide-header hide-footer>
      <button type="button" aria-label="Close" class="close" @click="$bvModal.hide('change_password')">×</button>
      <h4 class="mb-4">Change Password</h4>
      <b-form @submit.prevent="submit">
        <b-form-group id="password-group" label-for="password">
          <b-form-input
            v-model="passwords.current"
            id="password"
            type="password"
            required
            placeholder="Please enter your current password"
          ></b-form-input>
        </b-form-group>
        <hr />
        <b-form-group id="new-group" label-for="new">
          <b-form-input
            v-model="passwords.new"
            id="new"
            type="password"
            required
            placeholder="Please enter your new password"
          ></b-form-input>
        </b-form-group>
        <b-form-group id="repeat-group" label-for="repeat">
          <b-form-input
            v-model="passwords.repeat"
            class="mb-1"
            id="repeat"
            type="password"
            required
            placeholder="Please repeat your new password"
          ></b-form-input>
        </b-form-group>
        <div v-if="passwords.new !== passwords.repeat && passwords.repeat" class="alert alert-danger">Passwords do not match.</div>
        <div v-if="passwords.current === passwords.new && passwords.new" class="alert alert-danger">Current and new passwords cannot be the same.</div>
        <b-button :disabled="formStatus === 'submitting' || !canSubmit" type="submit" pill class="btn-yellow px-5">Proceed</b-button>
      </b-form>
    </b-modal>
    <!-- End modal -->
    <!-- License modal -->
    <b-modal id="license_info" size="lg" centered hide-header hide-footer v-if="licenseStatus && licenseStatus.loaded">
      <button type="button" aria-label="Close" class="close" @click="$bvModal.hide('license_info')">×</button>
      <h4 class="mb-4">License Allocation</h4>
      <b-row>
        <b-col cols="4">
          Licenses:
        </b-col>
        <b-col>
          <span v-for="_license in license.info.licenseKeys" :key="_license.id">
            <b>{{ _license.id }}</b><small>, expires on <b>{{ formatDate(_license.expiry) }}</b></small><br />
          </span>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4">
          User Allocations:
        </b-col>
        <b-col>
          <b-row class="mb-0">
            <b-col cols="5">
              Available user licenses:
            </b-col>
            <b-col>
              <b>{{ license.info.licensedUsers === 0 ? 'Unlimited' : license.info.licensedUsers }}</b> <small>users</small>
            </b-col>
          </b-row>
          <b-row class="mb-0">
            <b-col cols="5">
              Allocated user licenses:
            </b-col>
            <b-col>
              <b>{{ license.info.allocatedUsers }}</b> <small>users</small>
            </b-col>
          </b-row>
          <b-row class="mb-0">
            <b-col cols="5">
              Total system users:
            </b-col>
            <b-col>
              <b>{{ license.info.totalUsers }}</b> <small>users</small>
            </b-col>
          </b-row>
          <b-row class="mb-0">
            <b-col cols="5">
              Available user licenses:
            </b-col>
            <b-col>
              <b>{{ license.info.availableUsers >= 0 ? license.info.availableUsers : 'Unlimited' }}</b> <small>users</small>
            </b-col>
          </b-row>
          <b-row class="mb-0 text-warning" v-if="license.info.userOverallocation > 0">
            <b-col cols="5">
              Overallocated user licenses:
            </b-col>
            <b-col>
              <b>{{ license.info.userOverallocation }}</b> <small>users</small>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4">
          Project Allocations:
        </b-col>
        <b-col>
          <b-row class="mb-0">
            <b-col cols="5">
              Available project licenses:
            </b-col>
            <b-col>
              <b>{{ license.info.licensedProjects === 0 ? 'Unlimited' : license.info.licensedProjects }}</b> <small>projects</small>
            </b-col>
          </b-row>
          <b-row class="mb-0">
            <b-col cols="5">
              Allocated project licenses:
            </b-col>
            <b-col>
              <b>{{ license.info.allocatedProjects }}</b> <small>projects</small>
            </b-col>
          </b-row>
          <b-row class="mb-0">
            <b-col cols="5">
              Available project licenses:
            </b-col>
            <b-col>
              <b>{{ license.info.availableProjects >= 0 ? license.info.availableProjects : 'Unlimited' }}</b> <small>projects</small>
            </b-col>
          </b-row>
          <b-row class="mb-0 text-warning" v-if="license.info.projectOverallocation > 0">
            <b-col cols="5">
              Overallocated user licenses:
            </b-col>
            <b-col>
              <b>{{ license.info.projectOverallocation }}</b> <small>projects</small>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4">
          Licensed users:
        </b-col>
        <b-col>
          <span v-for="_user in licensedUsers" :key="_user._id">
            <b>{{ _user.name }}</b><br />
          </span>
        </b-col>
      </b-row>
      <div v-if="loadLicense">
        <b-form @submit.prevent="loadNewLicense">
          <b-form-group id="license-group" label-for="license">
            <b-form-textarea
              v-model="newLicense.license"
              id="license"
              required
              rows="3"
              max-rows="6"
              placeholder="Please enter license string provided"
            ></b-form-textarea>
          </b-form-group>
          <b-form-group id="active-group" label-for="active">
            <b-form-checkbox
              v-model="newLicense.active"
              id="license"
              required
              :value="true"
              :unchecked-value="false"
            >Activate license immediately</b-form-checkbox>
          </b-form-group>
        </b-form>
      </div>
      <b-button v-if="!loadLicense" type="button" pill class="btn-yellow px-5" @click.prevent="loadLicense = true">Load new license</b-button>
      <b-button :disabled="!newLicense.license" v-if="loadLicense" type="button" pill class="btn-yellow px-5 mr-2" @click.prevent="saveLicense()">Save new license</b-button>
      <b-button v-if="loadLicense" type="button" pill class="btn-blue px-5" @click.prevent="loadLicense = false">Cancel</b-button>
    </b-modal>
    <!-- End modal -->
    <div class="footer-right right-100">&copy; 2024 Copyright Envisionit Deep AI. All rights reserved.</div>
    <div class="footer-left left-100">Version 1.2.0</div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapActions, mapState } from 'vuex'

export default {
  data () {
    return {
      formStatus: null,
      passwords: {
        current: null,
        new: null,
        repeat: null
      },
      loadLicense: false,
      savedLicense: false,
      newLicense: {
        license: null,
        active: true
      }
    }
  },
  computed: {
    ...mapState({
      auth: state => state.auth,
      license: (state) => state.license
    }),
    changeStatus () {
      return this.auth.status.changed
    },
    canSubmit () {
      if (this.passwords.current) {
        if (this.passwords.new) {
          if (this.passwords.current !== this.passwords.new) {
            if (this.passwords.repeat) {
              if (this.passwords.new === this.passwords.repeat) {
                return true
              } else {
                return false
              }
            } else {
              return false
            }
          } else {
            return false
          }
        } else {
          return false
        }
      } else {
        return false
      }
    },
    licenseStatus () {
      return this.license.status
    },
    licenseStatusSaved () {
      return this.license.status.saved
    },
    licenseStatusLoaded () {
      return this.license.status.loaded
    },
    licensedUsers () {
      if (this.licenseStatusLoaded) {
        const _users = JSON.parse(JSON.stringify(this.license.info.licensedUserList))
        _users.sort((a, b) => { return a.name > b.name ? 1 : (a.name < b.name ? -1 : 0) })
        return _users
      } else {
        return []
      }
    }
  },
  methods: {
    ...mapActions('auth', ['logout', 'change']),
    ...mapActions('license', {
      getLicense: 'get',
      saveNewLicense: 'update'
    }),
    doLogout: function () {
      this.logout()
      this.$router.push('/login')
    },
    changePassword: function () {
      this.$bvModal.show('change_password')
    },
    submit: function () {
      this.formStatus = 'submitting'
      this.change(this.passwords)
    },
    formatDate: function (date) {
      try {
        const _date = moment(date)
        return _date.format('MMM Do, YYYY')
      } catch (error) {
        console.log(error)
        return date
      }
    },
    saveLicense: function () {
      this.saveNewLicense(this.newLicense)
    }
  },
  created () {
    this.getLicense()
  },
  watch: {
    changeStatus (value) {
      if (value === true) {
        this.$bvModal.hide('change_password')
      }
    },
    licenseStatusSaved () {
      console.log('saved', this.licenseStatus.saved)
      if (this.licenseStatus.saved) {
        this.savedLicense = true
        this.getLicense()
      }
    },
    licenseStatusLoaded () {
      console.log('loaded', this.licenseStatus.loaded, this.savedLicense)
      if (this.licenseStatus.loaded && this.savedLicense) {
        this.savedLicense = false
        this.newLicense = {
          license: null,
          active: true
        }
        this.loadLicense = false
        this.$nextTick(() => {
          this.$bvModal.show('license_info')
        })
      }
    },
    'passwords.current' () {
      this.formStatus = null
    },
    'passwords.new' () {
      this.formStatus = null
    },
    'passwords.repeat' () {
      this.formStatus = null
    }
  }
}
</script>
