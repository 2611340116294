import 'es6-promise/auto'
import 'whatwg-fetch'
import Vue from 'vue'
import App from './App.vue'
import { router } from './helpers'
import { store } from './store'
import VueApexCharts from 'vue-apexcharts'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueUploadComponent from 'vue-upload-component'
import '@/assets/css/custom.scss'
import '@/assets/css/extras.scss'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSync, faFill, faTint, faAdjust, faSun, faExpand, faChevronLeft, faChevronRight, faHome, faSortAmountDown, faSortNumericDown, faSortNumericUp, faSortAmountUp, faFilter, faLungs, faSignOutAlt, faSearch, faCog, faBell, faExclamationTriangle, faHeartbeat, faExpandArrowsAlt, faCheckCircle, faTimesCircle, faPlus, faPoll, faTrash, faPen, faUpload, faUndo, faRedo, faCheck, faTimes, faDna, faEye, faEyeSlash, faUserLock, faCheckSquare, faSquare, faCommentDots, faInfo, faCircle, faPhotoVideo, faClone, faMicrochip, faCrop, faCogs, faHourglassStart, faChartArea, faClipboardList, faUsersCog, faSitemap, faCheckDouble, faChevronDown, faChevronUp, faFileCsv, faFileCode, faFileAlt, faCamera, faArrowCircleRight, faIdBadge } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faSync, faFill, faTint, faAdjust, faSun, faEye, faEyeSlash, faExpand, faChevronLeft, faChevronRight, faHome, faLungs, faSignOutAlt, faSearch, faCog, faBell, faExclamationTriangle, faHeartbeat, faExpandArrowsAlt, faCheckCircle, faTimesCircle, faPlus, faPoll, faTrash, faPen, faUpload, faUndo, faRedo, faCheck, faTimes, faDna, faEye, faEyeSlash, faUserLock, faCheckSquare, faSquare, faCommentDots, faInfo, faCircle)
library.add(faCamera, faFilter, faSortAmountDown, faSortNumericDown, faSortNumericUp, faSortAmountUp, faPhotoVideo, faClone, faMicrochip, faCrop, faCheckDouble, faCogs, faHourglassStart, faChartArea, faClipboardList, faUsersCog, faSitemap, faChevronDown, faChevronUp, faFileCsv, faFileCode, faFileAlt, faArrowCircleRight, faIdBadge)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(require('vue-moment'))
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
Vue.component('file-upload', VueUploadComponent)

Vue.config.productionTip = false

Vue.filter('formatSize', function (bytes) {
  if (!bytes) return 'Unknown'
  const decimals = 2
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
})

if (Array.prototype.equals) {
  console.warn('Overriding existing Array.prototype.equals. Possible causes: New API defines the method, there\'s a framework conflict or you\'ve got double inclusions in your code.')
}

// eslint-disable-next-line no-extend-native
Array.prototype.equals = function (array) {
  if (!array) {
    return false
  }

  if (this.length !== array.length) {
    return false
  }

  for (var i = 0, l = this.length; i < l; i++) {
    if (this[i] instanceof Array && array[i] instanceof Array) {
      if (!this[i].equals(array[i])) {
        return false
      }
    } else if (this[i] !== array[i]) {
      return false
    }
  }

  return true
}

// eslint-disable-next-line no-extend-native
Object.defineProperty(Array.prototype, 'equals', { enumerable: false })

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
