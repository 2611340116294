import { labelledImagesService } from '../services'

const state = {
  aiImages: [],
  aiLoaded: false,
  aiLoading: false,
  labelledImages: [],
  loaded: false,
  loading: false,
  downloading: false,
  batchLoading: false,
  batches: [],
  labelledImagesSingle: [],
  aiImagesSingle: [],
  aiLoadedSingle: false,
  aiLoadingSingle: false,
  loadedSingle: false,
  loadingSingle: false
}

const actions = {
  async fetchLabelledImages2 ({ commit }, projectId) {
    commit('clearLabelledImages')
    commit('setLoading', true)
    try {
      const response = await labelledImagesService.getLabelledImages(projectId)
      commit('setLoading', false)
      commit('setLabelledImages', response)
    } catch (error) {
      console.error('Error fetching labelled images:', error)
      commit('setLoading', false)
    }
  },
  // problem
  async fetchAILabelledImages ({ commit }, projectId) {
    // // console.log('ai pul')
    commit('clearAILabelledImages')
    commit('setAILoading', true)
    try {
      const response = await labelledImagesService.getAILabelledImages(projectId)
      commit('setAILoading', false)
      commit('setAILabelledImages', response)
    } catch (error) {
      console.error('Error fetching labelled images:', error)
      commit('setAILoading', false)
    }
  },
  async fetchLabelledImages2Single ({ commit }, payload) {
    const projectId = payload.projectId
    const imgId = payload.imageID
    commit('clearLabelledImagesSingle')
    commit('setLoadingSingle', true)
    try {
      const response = await labelledImagesService.getLabelledImagesSingle(projectId, imgId)
      commit('setLoadingSingle', false)
      commit('setLabelledImagesSingle', response)
    } catch (error) {
      console.error('Error fetching labelled images:', error)
      commit('setLoadingSingle', false)
    }
  },
  // problem
  async fetchAILabelledImagesSingle ({ commit }, payload) {
    const projectId = payload.projectId
    const imgId = payload.imageID
    // // console.log('ai pul')
    commit('clearAILabelledImagesSingle')
    commit('setAILoadingSingle', true)
    try {
      const response = await labelledImagesService.getAILabelledImagesSingle(projectId, imgId)
      commit('setAILoadingSingle', false)
      commit('setAILabelledImagesSingle', response)
    } catch (error) {
      console.error('Error fetching labelled images:', error)
      commit('setAILoadingSingle', false)
    }
  },
  async downloadLabels ({ commit }, payload) {
    commit('setDownloading', true)
    try {
      await labelledImagesService.download(payload)
      commit('setDownloading', false)
      // // // // console.log('download', payload)
    } catch (error) {
      console.error('Error downloading labelled images:', error)
      commit('setDownloading', false)
    }
  },
  async getBatches ({ commit }) {
    commit('clearBatches')
    commit('gettingBatches', true)
    try {
      const response = await labelledImagesService.getBatches()
      commit('setBatches', response)
      commit('gettingBatches', false)
    } catch (error) {
      console.error('Error downloading labelled images:', error)
      commit('gettingBatches', false)
    }
  },
  async downloadBatches ({ commit }, payload) {
    try {
      labelledImagesService.downloadBatches(payload)
    } catch (error) {
      console.error('Error downloading images:', error)
      commit('gettingBatches', false)
    }
  }
}

const mutations = {
  setLoading (state, loading) {
    state.loaded = !loading
    state.loading = loading
  },
  setAILoading (state, loading) {
    state.aiLoaded = !loading
    state.aiLoading = loading
  },
  setLoadingSingle (state, loading) {
    state.loadedSingle = !loading
    state.loadingSingle = loading
  },
  setAILoadingSingle (state, loading) {
    state.aiLoadedSingle = !loading
    state.aiLoadingSingle = loading
  },
  setDownloading (state, status) {
    state.downloading = status
  },
  setLabelledImages (state, images) {
    state.labelledImages = images
  },
  setLabelledImagesSingle (state, images) {
    state.labelledImagesSingle = images
  },
  clearLabelledImages (state) {
    state.labelledImages = []
  },
  clearLabelledImagesSingle (state) {
    state.labelledImagesSingle = []
  },
  setAILabelledImages (state, images) {
    state.aiImages = images
  },
  setAILabelledImagesSingle (state, images) {
    state.aiImagesSingle = images
  },
  clearAILabelledImages (state) {
    state.aiImages = []
  },
  clearAILabelledImagesSingle (state) {
    state.aiImagesSingle = []
  },
  setBatches (state, batches) {
    state.batches = batches
  },
  gettingBatches (state, status) {
    state.batchLoading = status
  },
  clearBatches (state) {
    state.batches = []
  }
}

export const labelledImagesSubmission = {
  namespaced: true,
  state,
  actions,
  mutations
}
