import { apiRoute } from '../helpers/api-route'
const state = {
  annotations: [],
  label: [],
  set: [],
  image: [],
  phase: {},
  isOpen: false,
  loading: false,
  windowOptions: []
}

const actions = {
  clear ({ commit }) {
    commit('clear')
  },
  toggleVisablity ({ commit }, val) {
    commit('toggleV', val)
  },
  getAnnotationsMap ({ commit }, payload) {
    commit('toggleLoading')
    var image = payload.image
    var phase = payload.phaseData
    var images = payload.labeledSubmissions
    var members = payload.members
    var imagesAi = payload.aiImages
    if ((phase.phase !== 1)) {
      image.details.iou = images[0].image.accuracy.find(ac => ac.project === phase.projectId) ? images[0].image.accuracy.find(ac => ac.project === phase.projectId).value : 0
    }
    if (!image.details.aiIov && (phase.phase !== 1)) {
      image.details.aiIov = images[0].image.ai_accuracy.filter(a => a.project === phase.projectId).length > 0 ? images[0].image.ai_accuracy.filter(a => a.project === phase.projectId).slice(-1)[0].value : null
    }
    commit('setPahse', phase)
    var selectedUser = []
    console.log('Final2', image)
    // console.log('Final2', payload)
    var selectedImage = []
    selectedImage.push(image.url)
    // var rejectPayload = {
    //   projectId: this.projectId,
    //   imageID: image.id,
    //   phase: this.selected
    // }
    var img = new Image()
    img.src = image.url
    img.onload = (e) => {
      // // // // console.log(img.height)
      selectedImage.push([img.width, img.height])
      // var tmp = images[0].otherImages
      if (images[0] && images[0].otherImages && (!image.details.otherImages || image.details.otherImages.length === 0)) {
        image.details.otherImages = images[0].otherImages.filter(oi => oi._id !== image.id)
      } else if (image.details.otherImages) {
        image.details.otherImages = image.details.otherImages.filter(oi => oi._id !== image.id)
      }
      // console.log(image.details.otherImage)
      selectedImage.push(image.details)
      // // // // console.log(image.details)
      // // // // console.log(image)
      let user = null
      if (phase.phase !== 1) {
        const _tempImg = images // labeled submissions
        user = getUserFromImage(_tempImg, image.id, false, members)
        // // // // console.log(image)
      }
      var userAi
      var _tempAi = []
      var _tempImgAi = []
      // // console.log('image')
      // // console.log(image)
      if (image.analyzed) {
        // console.log('img', image)

        if (image.details && image.details.image_set && image.details.image_set.name) {
          _tempAi = JSON.parse(JSON.stringify(imagesAi.find(ig => ig.image.image_set.name === image.details.image_set.name && ig.image.image_set.sequence === image.details.image_set.sequence)))
          _tempImgAi = _tempAi.otherImages
          var _curAi = _tempAi
          _curAi.otherImages = null
          // console.log('tempai0', _tempImgAi)
          _tempImgAi = _tempImgAi.concat(_curAi)
        } else {
          _tempImgAi = imagesAi
        }
        // // // // console.log(image)
        // // // // console.log(_tempImgAi)
        // // console.log('tempai1', _tempImgAi)
        userAi = getUserFromImage(_tempImgAi, image.id, true, members)
        if (user && user.boxes) {
          selectedUser.boxes = userAi.boxes.concat(user.boxes)
        }
      } else {
        selectedUser = user
      }

      let _averageIov = 0
      if (selectedImage[2] && selectedImage[2].otherImages && selectedImage[2].otherImages.length > 0) {
        const _others = selectedImage[2].otherImages
        const _iovs = []
        _others.forEach(_image => {
          const _accuracy = _image.image.accuracy ? _image.image.accuracy.find(a => a.project === phase.projectId) : null
          if (_image.pathologies && _image.pathologies.length > 0) {
            const _pathologies = _image.pathologies.map(_record => { return _record[0] })
            if (_pathologies[0]) {
              _iovs.push(_accuracy)
            }
          }
        })
        // console.log('iov check', _iovs)
        selectedImage[2].aveIou = 0
        if (_iovs.length > 0) {
          _averageIov = _iovs.reduce((total, item) => total + item.value, 0) / _iovs.length
          selectedImage[2].aveIou = _averageIov
        }

        if (_averageIov === 0) {
          selectedImage[2].aveIou = selectedImage[2].iou / (selectedImage[2].otherImages.length + 1)
          _averageIov = selectedImage[2].aveIou
        }
      }

      var annotations = []
      var windows = []
      if (selectedImage[2] && selectedImage[2].otherImages && selectedImage[2].otherImages.length > 0) {
        selectedImage[2].imgName = selectedImage[2].image_set.name
        windows.push(selectedImage[2].image_set.window)
        var _others = selectedImage[2].otherImages
        _others.forEach(setImg => {
          // var _selectedUser = []
          var _selectedImage = []
          let _user = null
          var imgUrl = apiRoute() + '/image/' + (phase.phase === 1 ? setImg._id : setImg.image._id)
          // // // // console.log(imgUrl)
          _selectedImage.push(imgUrl) //
          var _img = new Image()
          _img.src = imgUrl //
          // // // // console.log('setImg.image.image.name', setImg.image.image.name)
          _selectedImage.push([img.width, img.height])
          _selectedImage.push(image.details)//
          if (phase.phase !== 1) {
            _user = getUserFromImage(_others, setImg.image._id, false, members)
          }

          var _userAi
          if (image.analyzed || ((phase.phase === 6 || phase.phase === 7) && imagesAi.find(ig => ig.image.image_set.name === image.details.image_set.name && ig.image.image_set.sequence === image.details.image_set.sequence))) {
            // // // // console.log(_tempImgAi)
            // // console.log('tempai3', imagesAi.find(ig => ig.image.image_set.name === image.details.image_set.name && ig.image.image_set.sequence === image.details.image_set.sequence))
            // // console.log('tempai3', _tempImgAi.find(ig => ig.image.image_set.name === image.details.image_set.name && ig.image.image_set.sequence === image.details.image_set.sequence))
            _userAi = getUserFromImage(_tempImgAi, setImg.image._id, true, members)
            if (_user && _user.boxes) {
              _user.boxes = _userAi.boxes.concat(_user.boxes)
            }
          }
          // else {
          //   selectedUser = _user
          // }
          // console.log(setImg)
          // console.log(phase.phase)
          let name = null
          let set = null
          if (phase.phase === 1) {
            windows.push(setImg.image_set ? setImg.image_set.window : setImg.image.image_set.window)
            name = setImg.image_set ? setImg.image_set.name : setImg.image.image_set.name
            set = setImg.image_set ? setImg.image_set : setImg.image.image_set
          } else {
            windows.push(setImg.image.image_set.window)
          }
          const _accuracy = setImg.image.accuracy ? setImg.image.accuracy.filter(a => a.project === phase.projectId) : null
          const _aiAccuracy = setImg.image.ai_accuracy ? setImg.image.ai_accuracy.filter(a => a.project === phase.projectId) : null
          // console.log('iov check', _averageIov)
          var details = {
            created: (phase.phase === 1 ? setImg.created_at : setImg.image.created_at),
            format: (phase.phase === 1 ? setImg.image.contentType : setImg.image.image.contentType),
            imgName: (phase.phase === 1 ? name : setImg.image.image_set.name),
            meta: (phase.phase === 1 ? setImg.metadata : setImg.image.metadata),
            iou: (phase.phase === 1 ? 0 : (_accuracy && _accuracy.length > 0 ? _accuracy.slice(-1)[0].value : 0)),
            aveIou: _averageIov,
            aiIov: phase.phase === 6 || phase.phase === 7 ? (_aiAccuracy && _aiAccuracy.length > 0 ? _aiAccuracy.slice(-1)[0].value : 0) : null,
            image_set: (phase.phase === 1 ? set : setImg.image.image_set && setImg.image.image_set.name ? setImg.image.image_set : null)
          }
          annotations.push({ image: _selectedImage, boxes: _user ? _user.boxes : [], labelled: _user ? _user.labelled : false, imgId: (phase.phase === 1 ? setImg._id : setImg.image._id), details: details })
        })
      }
      // // console.log('1', typeof annotations)
      // // console.log('2', annotations)
      // // console.log('22', image)
      var firstRecord = {}
      // // console.log('windows', [...new Set(windows)])
      if (image.details && image.details.image_set && image.details.image_set.name && annotations.length > 0) {
        firstRecord = { image: selectedImage, boxes: selectedUser ? selectedUser.boxes : [], labelled: selectedUser ? selectedUser.labelled : false, imgId: phase.imageID, details: selectedImage[2] }
        annotations = annotations.length > 0 ? [firstRecord, ...annotations] : annotations.push(firstRecord)
        // annotations = annotations.sort((ann1, ann2) => ann1.details.image_set.identifier.localeCompare(ann2.details.image_set.identifier))
      } else if (annotations.length === 0) {
        firstRecord = { image: selectedImage, boxes: selectedUser ? selectedUser.boxes : [], labelled: selectedUser ? selectedUser.labelled : false, imgId: phase.imageID, details: selectedImage[2] }
        annotations.push(firstRecord)
      }
      commit('setWindows', [...new Set(windows)])
      commit('setSet', annotations)
      commit('setAnnotations', selectedUser ? selectedUser.boxes : [])
      commit('setImage', selectedImage)
      commit('toggleLoading')
    }
  }
  // async getMembers ({ commit }, projectId) {
  //   commit('getAllMembersRequest')
  //   userService.getMembers(projectId).then(
  //     (members) => commit('getAllMembersSuccess', members),
  //     (error) => commit('getAllMembersFailure', error)
  //   )
  // }
}

const mutations = {
  clear (state, val) {
    state.isOpen = val
    state.annotations = []
    state.label = []
    state.set = []
    state.image = []
    state.phase = {}
    state.isOpen = false
    state.loading = false
    state.windowOptions = []
  },
  toggleV (state, val) {
    state.isOpen = val
  },
  toggleLoading (state) {
    state.loading = !state.loading
  },
  setPahse (state, phaseData) {
    state.phase = phaseData
  },
  setAnnotations (state, data) {
    state.annotations = data
  },
  setWindows (state, data) {
    state.windowOptions = data
  },
  setSet (state, data) {
    state.set = data
  },
  setImage (state, data) {
    state.image = data
  }
}

export const BottomSheet = {
  namespaced: true,
  state,
  actions,
  mutations
}

/*
function LightenDarkenColor (col, amt) {
  col = parseInt(col, 16)
  return (((col & 0x0000FF) + amt) |
    (((col >> 8) & 0x00FF) + amt) << 8 |
    ((col >> 16) + amt) << 16
  ).toString(16)
}
*/

function getUserFromImage (imageData, imgId, aiFlag, members) {
  // // // // console.log('img da', imageData)
  // // // // console.log('img da', imgId)
  // // // // console.log('img da', aiFlag)
  // // // // console.log('img da', members)
  let _labelled = false
  if (!Array.isArray(imageData)) {
    return { boxes: [], labelled: _labelled }
  }
  // // // // console.log('image data', imageData)
  // // // // console.log('image dataai', aiFlag)
  var users = []
  // // // // console.log(imageData)
  // // // // console.log(imgId)
  //  _img.otherImages && _img.otherImages.length > 0 ? _img.otherImages.find(__img => __img.image._id === imgId) :
  const img = imageData.find(_img => (_img.image._id === imgId))
  if (img) {
    // // // console.log(img)
    // // // console.log(aiFlag)
    // var bbColours = ['#C77D3D', '#A4414A', '#7D1E5F', '#5C0F6B', '#3C0A6F', '#1E0A6E', '#0B0C63', '#0A1052', '#0C1441', '#13192F', '#1D1E1E', '#2A2213', '#3A2709', '#4B2C00', '#5D3100', '#6F3600', '#813B00', '#933F00', '#A54200', '#B74400']
    const bbColours = ['#013220', '#012a32', '#012144', '#001956', '#001167', '#000879', '#00008b', '#2a0c74', '#55175d', '#802246', '#aa2e2e', '#d43917', '#ff4500', '#e13916', '#c32e2b', '#a52241', '#871757', '#690c6c', '#4b0082']
    // // console.log(img)
    const __pathologies = img.pathologies.sort((a, b) => a.created_by.localeCompare(b.created_by))
    __pathologies.forEach(_p => {
      var _bounding = []
      let _colour = '#051135'
      if (!aiFlag) {
        // _colour = '#' + LightenDarkenColor(bbColours.shift().replace('#', ''), -20)
        _colour = bbColours.shift()
      }
      var user = !aiFlag ? members.find(mem => mem._id === _p.created_by) : { _id: 'ai', personal: { fullname: 'AI' } } // members
      var indexes = Object.keys(_p).slice(0, -1)
      // // // // console.log(indexes)
      /* We have diagnosis now to show that there're no pathologies
      if (indexes.length === 0) {
        _bounding.push('Normal')
      }
      /* */
      indexes.forEach(ind => {
        const pathName = _p[ind].name
        // console.log('bboxes: ', _p[ind])
        const coordinates = _p[ind].coords ? JSON.parse(JSON.stringify(_p[ind].coords)) : null
        const polygon = _p[ind].freehandpoints ? JSON.parse(JSON.stringify(_p[ind].freehandpoints)) : null
        const window = _p[ind].window_coords ? JSON.parse(JSON.stringify(_p[ind].window_coords)) : coordinates
        const other = _p[ind].other ? JSON.parse(JSON.stringify(_p[ind].other)) : null
        const stats = []
        if (_p[ind].mapped_stats) {
          for (const prop in _p[ind].mapped_stats) {
            if (_p[ind].mapped_stats[prop]) {
              stats.push(_p[ind].mapped_stats[prop])
            }
          }
        }
        const tools = []
        if (_p[ind].psa) {
          tools.push({ psa: _p[ind].psa })
        }
        if (!aiFlag) {
          _labelled = true
          _bounding.push({ colour: _colour, coordinates: coordinates, polygon: polygon, window: window, other: other, stats: stats, pathology: pathName, showBoundingBox: true, tools: tools })
        } else {
          if (coordinates && coordinates.x && 1 === 2) {
            if (pathName === 'apchest' || pathName === 'heart') {
              coordinates.x = coordinates.x - (coordinates.w / 2)
              coordinates.y = coordinates.y + (coordinates.h / 2)
            } else {
              coordinates.x = coordinates.x - (coordinates.w / 2)
              coordinates.y = coordinates.y - (coordinates.h / 2)
            }
          }

          _labelled = true
          _bounding.push({ colour: _colour, coordinates: coordinates, polygon: polygon, pathology: pathName, showBoundingBox: true, confidence: _p[ind].confidence })
        }
      })
      var _tags = []
      if (img.tags && img.tags.find(d => d.created_by === _p.created_by) && img.tags.find(d => d.created_by === _p.created_by)[0]) {
        var tempTags = img.tags.find(d => d.created_by === _p.created_by)
        var keyst = Object.keys(tempTags)
        keyst.forEach(_k => {
          if (_k !== 'created_by') {
            // // console.log('_k ', _k)
            // // console.log('_k ', sub[_k])
            _tags.push(tempTags[_k])
          }
        })
      }
      var _diagnoses = []
      if (img.diagnoses && img.diagnoses.find(d => d.created_by === _p.created_by) && img.diagnoses.find(d => d.created_by === _p.created_by)[0]) {
        var tempDias = img.diagnoses.find(d => d.created_by === _p.created_by)
        var keys = Object.keys(tempDias)
        keys.forEach(_k => {
          if (_k !== 'created_by') {
            // // console.log('_k ', _k)
            // // console.log('_k ', sub[_k])
            _diagnoses.push(tempDias[_k])
          }
        })
      }
      users.push({ boundingBoxes: _bounding, id: user._id, name: user.personal.fullname, showBoundingBoxes: true, tags: _tags, diagnoses: _diagnoses })
    })
  }
  // // // // console.log('bboxes', users)

  // // // // console.log('img da', users)
  return { boxes: users, labelled: _labelled }
}
